/* 
 * Copyright 2018 Benchmark Software
 * All rights reserved
 */

$(document).ready(function () {
    $('body').bootstrapMaterialDesign();
    window.setTimeout(function () {
        $('#hero').addClass('show-responses');
    }, 100);
});